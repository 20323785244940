import React from 'react'
// Import your Firebase services from your firebase.config file
import { app, auth, db } from './firebase-config.js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Button } from '@mui/material';

// Initialize Cloud Functions for Firebase
const functions = getFunctions(app);

export default function SendEmail() {

    // Call the 'sendEmailNotifications' Cloud Function
    const sendEmailNotifications = httpsCallable(functions, 'sendEmailNotifications');

    const onPressSendEmail = () => {
        console.log('send email')
        // Assuming 'firebase' has been initialized in your web app
        sendEmailNotifications({ contestId: 'your_contest_id' })
            .then((result) => {
                // Read result of the Cloud Function.
                console.log(result.data);
            })
            .catch((error) => {
                // Getting the Error details.
                console.log(error.code);    // Error code
                console.log(error.message); // Error message
                console.log(error.details);  // Additional details
            });
    }

    return (
        <div>
            <Button onClick={onPressSendEmail}>
                Send Email
            </Button>
        </div>
    )
}
