import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { doc, getDoc, addDoc, collection, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Button, TextField, Box, Typography, Container, Grid, IconButton, Card, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../auth/AuthContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './ContestForm.css'
import LoginPrompt from '../auth/LoginPrompt'; // Make sure this path is correct
import { logEvent } from "firebase/analytics";
import { appAnalytics } from '../firebase-config';

const CreateContest = () => {
    const [contestName, setContestName] = useState('');
    const [games, setGames] = useState([{ homeTeam: '', awayTeam: '', kickoffTime: null }]);
    const [hasError, setHasError] = useState(false)
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [cutoffTime, setCutoffTime] = useState(new Date())
    const [amountPerSquare, setAmountPerSquare] = useState('');
    const [upcomingGames, setUpcomingGames] = useState([]);
    const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
    const [loading, setLoading] = useState(true);

    const [paypalLink, setPaypalLink] = useState('');
    const [venmoLink, setVenmoLink] = useState('');
    const [cashAppLink, setCashAppLink] = useState('');

    useEffect(() => {
        setHasError(false)
    }, [contestName, games])

    useEffect(() => {
        if (games.length === 1) {
            // If there's only one game, calculate the cutoff time to be 30 minutes before its kickoff.
            const singleGameTime = new Date(games[0].kickoffTime);
            singleGameTime.setMinutes(singleGameTime.getMinutes() - 30);
            setCutoffTime(singleGameTime);
        } else if (games.length > 1) {
            // If there are multiple games, find the earliest kickoff time first.
            const earliestGameTime = games
                .map(game => new Date(game.kickoffTime))
                .filter(time => !isNaN(time.getTime()))
                .reduce((a, b) => a < b ? a : b, new Date(Infinity));

            // Then, calculate the cutoff time to be 30 minutes before the earliest kickoff.
            if (!isNaN(earliestGameTime.getTime())) {
                earliestGameTime.setMinutes(earliestGameTime.getMinutes() - 30);
                setCutoffTime(earliestGameTime);
            }
        }
    }, [games]);

    useEffect(() => {
        // Check if the auth state has been initialized
        if (currentUser !== undefined) { // or null, depending on your auth context's initial value
            setLoading(false); // Auth state is confirmed, so we can turn off the loader

            if (!currentUser) {
                // User is not logged in
                navigate('/login');
            } else {
                // User is logged in
                logEvent(appAnalytics, 'page_view', { uid: currentUser?.uid, page_path: '/create-contest' });
            }
        }
    }, [currentUser]);


    const fetchESPNData = async () => {
        try {
            const res = await fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?limit=1000&dates=20240205-20240228');
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return await res.json();
        } catch (error) {
            console.error('Error fetching ESPN data:', error);
        }
    }


    useEffect(() => {
        // fetch('https://us-central1-squares-prod-62239.cloudfunctions.net/fetchESPNData')
        fetchESPNData()
            .then(data => {
                if (data) {
                    const currentTime = new Date();
                    const futureGames = data.events.filter(game =>
                        new Date(game.date) > currentTime
                    ).map(game => ({
                        id: game.competitions[0].id, // competition ID
                        homeTeam: game.competitions[0].competitors.find(team => team.homeAway === 'home').team,
                        awayTeam: game.competitions[0].competitors.find(team => team.homeAway === 'away').team,
                        kickoffTime: game.date,
                        homeTeamColor: game.competitions[0].competitors.find(team => team.homeAway === 'home').team.color, // home team color
                        awayTeamColor: game.competitions[0].competitors.find(team => team.homeAway === 'away').team.color, // away team color
                        homeTeamId: game.competitions[0].competitors.find(team => team.homeAway === 'home').team.id, // home team ID
                        awayTeamId: game.competitions[0].competitors.find(team => team.homeAway === 'away').team.id // away team ID
                    }));
                    // futureGames.push({
                    //     id: 'compId',
                    //     homeTeam: "Kansas City Chiefs",
                    //     awayTeam: "San Francisco 49ers",
                    //     kickoffTime: new Date(2024, 1, 11, 5, 30),
                    //     homeTeamColor: 'e31837',
                    //     awayTeamColor: 'aa0000',
                    //     homeTeamId: '12',
                    //     awayTeamId: '25'
                    // })
                    setUpcomingGames(futureGames);
                    console.log('future games: ', futureGames)
                }
            })
            .catch(error => console.error('Error fetching data: ', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (games.length > 0 && contestName.length > 0) {
            try {
                const formattedGames = games.map(game => {
                    // Find the corresponding game in upcomingGames
                    const foundGame = upcomingGames.find(g => g.id === game.id);
                    return {
                        homeTeam: game.homeTeam.trim(),
                        awayTeam: game.awayTeam.trim(),
                        kickoffTime: game.kickoffTime,
                        homeTeamColor: foundGame?.homeTeamColor || '#345',
                        awayTeamColor: foundGame?.awayTeamColor || '#543',
                        homeTeamId: foundGame?.homeTeamId || 0,
                        awayTeamId: foundGame?.awayTeamId || 1,
                        competitionId: foundGame?.id || 0,

                    };
                }).filter(game => game.homeTeam !== '' && game.awayTeam !== '');

                const userRef = doc(db, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error("User does not exist in the database");
                    return;
                }

                const { humanName, squareName } = userSnap.data();

                const docRef = await addDoc(collection(db, 'contests'), {
                    name: contestName,
                    games: formattedGames,
                    creatorId: currentUser.uid,
                    cutoffTime: cutoffTime,
                    amountPerSquare: amountPerSquare,
                    paymentDetails: {
                        paypal: paypalLink,
                        venmo: venmoLink,
                        cashApp: cashAppLink,
                    },
                    participants: [{
                        id: currentUser?.uid,
                        humanName,
                        squareName,
                        squares: []
                    }]
                });
                navigate(`/contest/${docRef.id}`);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        } else {
            setHasError(true);
        }
    };


    const handleContestNameChange = (e) => {
        setContestName(e.target.value);
    };

    const handleGameChange = (index, e, team) => {
        const newGames = [...games];
        newGames[index][team] = e.target.value;
        setGames(newGames);
    };

    const addGame = () => {
        setGames([...games, { homeTeam: '', awayTeam: '' }]);
    };


    const handleSelectGame = (index, selectedGameId) => {
        const selectedGame = upcomingGames.find(game => game.id === selectedGameId);
        if (selectedGame) {
            const newGames = [...games];
            newGames[index] = {
                ...newGames[index],
                id: selectedGame.id,
                homeTeam: selectedGame.homeTeam.shortDisplayName,
                awayTeam: selectedGame.awayTeam.shortDisplayName,
                kickoffTime: new Date(selectedGame.kickoffTime),
                homeTeamColor: selectedGame.homeTeamColor,
                awayTeamColor: selectedGame.awayTeamColor,
                homeTeamId: selectedGame.homeTeamId,
                awayTeamId: selectedGame.awayTeamId,
                competitionId: selectedGame.id
            };
            setGames(newGames);
        }
    };

    if (loading) {
        return <CircularProgress />; // Show a loading indicator while checking the auth state
    }
    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom>Create Contest</Typography>
                <form onSubmit={handleSubmit} noValidate>
                    <TextField
                        label="Contest Name"
                        value={contestName}
                        onChange={handleContestNameChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    {games.map((game, index) => (
                        <Grid container spacing={2} key={index} alignItems="flex-end" style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth>
                                    <InputLabel variant='filled'>Games</InputLabel>
                                    <Select
                                        value={game.id || 'Games'}
                                        onChange={
                                            (e) => {
                                                handleSelectGame(index, e.target.value)
                                            }}
                                    >
                                        {upcomingGames.map(upcomingGame => (
                                            <MenuItem key={upcomingGame.id} value={upcomingGame.id}>
                                                {upcomingGame.homeTeam.shortDisplayName} vs {upcomingGame.awayTeam.shortDisplayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label={`Home Team`}
                                    value={game.homeTeam}
                                    onChange={(e) => handleGameChange(index, e, 'homeTeam')}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label="Away Team"
                                    value={game.awayTeam}
                                    onChange={(e) => handleGameChange(index, e, 'awayTeam')}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            {index === games.length - 1 && (
                                <Grid item xs={12} sm={2}>
                                    <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
                                        <IconButton onClick={addGame} color="primary">
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                        {/* <Typography>Add Game</Typography> */}
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Contest Cutoff Time"
                            value={cutoffTime}
                            onChange={(newValue) => {
                                setCutoffTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <Card variant="outlined" sx={{ p: 2, mt: 3, mb: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                            Payment Details
                        </Typography>
                        <TextField
                            label="Amount Per Square"
                            value={amountPerSquare}
                            onChange={(e) => setAmountPerSquare(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                        />
                        <TextField
                            label="PayPal Link"
                            value={paypalLink}
                            onChange={(e) => setPaypalLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Venmo Link"
                            value={venmoLink}
                            onChange={(e) => setVenmoLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Cash App Link"
                            value={cashAppLink}
                            onChange={(e) => setCashAppLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                    </Card>

                    <Box sx={{ mt: 3 }}>
                        <Button type="submit" variant="contained" color="primary">Create Contest</Button>
                        {hasError ? <p style={{ color: 'tomato' }}>Error Creating Contest</p> : null}
                    </Box>
                </form>
            </Box>
            {!currentUser && (
                <LoginPrompt open={openLoginPrompt} onClose={() => setOpenLoginPrompt(false)} />
            )}

        </Container>
    );
};

export default CreateContest;
