import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { app, db } from '../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Typography, Chip, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../auth/AuthContext';
import ContestForm from './ContestForm'
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { logEvent } from "firebase/analytics";
import { appAnalytics } from '../firebase-config';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ContestManagement = () => {
    // Initialize Cloud Functions for Firebase
    const functions = getFunctions(app);

    const { contestId } = useParams();
    const [contest, setContest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paymentUpdates, setPaymentUpdates] = useState({}); // To track payment updates
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const totalSquares = 100;

    useEffect(() => {
        const fetchContest = async () => {
            setLoading(true);
            const docRef = doc(db, 'contests', contestId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setContest({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.log('No such contest!');
                setContest(null); // Explicitly set contest to null if not found
            }
            setLoading(false);
        };

        fetchContest();
    }, [contestId]);

    useEffect(() => {
        // Check if the auth state has been initialized
        if (currentUser !== undefined) { // or null, depending on your auth context's initial value
            setLoading(false); // Auth state is confirmed, so we can turn off the loader

            if (!currentUser) {
                // User is not logged in
                navigate('/login');
            } else {
                // User is logged in
                logEvent(appAnalytics, 'page_view', { uid: currentUser?.uid, page_path: '/contest-management' });
            }
        }
    }, [currentUser]);

    const handleEmailClick = () => {
        const sendEmailNotifications = httpsCallable(functions, 'sendEmailNotifications');

        sendEmailNotifications({ contestId: contestId })
            .then((result) => {
                // Read result of the Cloud Function.
                console.log(result);
            })
            .catch((error) => {
                // Getting the Error details.
                console.log(error.code);    // Error code
                console.log(error.message); // Error message
                console.log(error.details);  // Additional details
            });
    }

    // Function to call when "Edit" is clicked on a contest
    const handleEditClick = () => {
        setIsFormVisible(true);
    };

    const handlePaymentChange = (participantId, amountPaid) => {
        setPaymentUpdates(prev => ({ ...prev, [participantId]: amountPaid }));
    };

    const isPaid = (participant) => {
        const totalDue = contest.amountPerSquare * participant.squares.length;
        const amountPaid = parseFloat(participant.amountPaid || 0);
        return amountPaid >= totalDue;
    };

    const handlePaymentUpdate = async (participantId) => {
        const amountPaid = paymentUpdates[participantId];
        if (amountPaid !== undefined) {
            const participantIndex = contest.participants.findIndex(p => p.id === participantId);
            if (participantIndex !== -1) {
                const updatedParticipants = [...contest.participants];
                updatedParticipants[participantIndex] = {
                    ...updatedParticipants[participantIndex],
                    amountPaid: parseFloat(amountPaid) // Parse the amountPaid as a float
                };

                const contestRef = doc(db, 'contests', contestId);
                await updateDoc(contestRef, { participants: updatedParticipants });

                setContest(prevState => ({
                    ...prevState,
                    participants: updatedParticipants
                }));

                // Clear the payment update for this participant
                setPaymentUpdates(prev => {
                    const updatesCopy = { ...prev };
                    delete updatesCopy[participantId];
                    return updatesCopy;
                });
            }
        }
    };

    // Calculate the total number of squares taken
    const totalSquaresTaken = contest?.participants.reduce((acc, participant) => {
        return acc + (participant.squares ? participant.squares.length : 0);
    }, 0);
    // Calculate the number of squares remaining
    const squaresRemaining = totalSquares - totalSquaresTaken;

    // Sort participants based on payment status: pending payments first
    const sortedParticipants = contest?.participants.sort((a, b) => {
        const aPaid = isPaid(a);
        const bPaid = isPaid(b);
        if (aPaid === bPaid) return 0; // Both have the same status, no change in order
        return aPaid ? 1 : -1; // Sort unpaid (false) before paid (true)
    });

    if (loading) {
        return <p>Loading...</p>;
    }

    if (loading) {
        return <CircularProgress />; // Show a loading indicator while checking the auth state
    }
    if (!contest && !loading) {
        return <Typography variant="h6" color="error">Contest not found</Typography>;
    }
    return (
        <Container>
            <Box flexDirection={'row'} marginBottom={'20px'} mt={4}>
                <h2>{contest.name}</h2>

            </Box>

            <Button
                variant="contained"
                color="success"
                startIcon={<EmailIcon />}
                onClick={handleEmailClick}
                style={{ marginBottom: 20, marginTop: 10 }}
            >
                Email Participants
            </Button>

            <Typography variant="h6" gutterBottom>
                Squares Remaining: {squaresRemaining}
            </Typography>

            <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                style={{ marginBottom: 20, marginTop: 10 }}
            >
                Edit Contest
            </Button>

            {/* Toggle the form based on isFormVisible */}
            {isFormVisible && (
                <ContestForm contestId={contestId} />
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Human Name</TableCell>
                            <TableCell>Square Name</TableCell>
                            <TableCell>Squares</TableCell>
                            <TableCell>Amount Owed</TableCell>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedParticipants.map((participant) => (
                            <TableRow key={participant.id}>
                                <TableCell>{participant.humanName}</TableCell>
                                <TableCell>{participant.squareName}</TableCell>
                                <TableCell>{participant.squares?.length}</TableCell>
                                <TableCell>${contest.amountPerSquare * participant.squares?.length}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={paymentUpdates[participant.id] ?? participant.amountPaid ?? 0}
                                        onChange={(e) => handlePaymentChange(participant.id, e.target.value)}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>
                                    {isPaid(participant) ?
                                        <Typography textAlign={'center'} bgcolor={'rgba(15,250,100,0.4)'}>Paid</Typography>
                                        :
                                        <Typography textAlign={'center'} bgcolor={'rgba(250,15,15,0.4)'}>Pending</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handlePaymentUpdate(participant.id)}
                                    >
                                        Update
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ContestManagement;
