import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCFc2vnA_esmrj7_h6hMxgtatBb2y2OznY",
    authDomain: "squares-prod-62239.firebaseapp.com",
    projectId: "squares-prod-62239",
    storageBucket: "squares-prod-62239.appspot.com",
    messagingSenderId: "768855717630",
    appId: "1:768855717630:web:2aa15ebbf08a5b47d5d7af",
    measurementId: "G-YS05CBYFXM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Get Firebase Authentication instance
const auth = getAuth(app);

// Initialize Firebase Analytics
const appAnalytics = getAnalytics(app);

export { app, appAnalytics, auth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail, db };