import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config'; // Import your Firebase config
import { Container, Typography, CircularProgress, Grid, Card, CardContent, Butto, Box, Button } from '@mui/material'; // Material-UI components
import PreStartGrid from './PreStartGrid';
import { useAuth } from '../auth/AuthContext';
import { doc, getDoc, onSnapshot, updateDoc, arrayUnion, getDocs, collection } from 'firebase/firestore';
import GamesDisplay from './GamesDisplay';
import { useNavigate } from 'react-router-dom';
import { logEvent } from "firebase/analytics";
import { appAnalytics } from '../firebase-config';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import './GameGridDisplay.css'

function ContestPage() {
    const { contestId } = useParams();
    const [contest, setContest] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const [userSquareNames, setUserSquareNames] = useState({})
    const [isParticipant, setIsParticipant] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        logEvent(appAnalytics, 'page_view', { uid: currentUser?.uid, page_path: '/contest-page' });
        // Real-time listener for the contest document
        const unsubscribe = onSnapshot(doc(db, "contests", contestId), (doc) => {
            if (doc.exists()) {
                let docData = doc.data()
                setContest(docData);
                // setIsParticipant(docData.participants?.some(p => p.id === currentUser?.uid));

                // Check if currentUser is defined
                if (!currentUser) {
                    console.error("currentUser is undefined");
                } else {
                    // Check if participants array is present in docData
                    if (!Array.isArray(docData.participants)) {
                        console.error("participants is not an array or is undefined in docData");
                    } else {
                        // Find if any participant matches the current user's ID
                        const isParticipant = docData.participants.some(p => {
                            // Debug: Log each participant check
                            console.log(`Checking participant ID: ${p.id}, currentUser ID: ${currentUser.uid}`);
                            return p.id === currentUser.uid;
                        });

                        // Debug: Log the result of the check
                        console.log("Is current user a participant:", isParticipant);

                        setIsParticipant(isParticipant);
                    }
                }
            } else {
                console.log("No such contest!");
            }
            setLoading(false);
        });

        const fetchParticipantNames = async () => {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const uSquareNames = {};
            usersSnapshot.forEach(doc => {
                uSquareNames[doc.id] = doc.data().squareName; // Assuming 'squareName' is the field
            });
            setUserSquareNames(userSquareNames)
            // Update the state or pass this map to the PreStartGrid component
        };

        fetchParticipantNames();

        // Check if we need to auto-join
        const urlParams = new URLSearchParams(window.location.search);
        const shouldAutoJoin = urlParams.get('autojoin') === 'true';

        if (shouldAutoJoin && currentUser) {
            handleJoinContest();
        }

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => unsubscribe();
    }, [contestId, currentUser]);

    const handleJoinContest = async () => {
        if (!currentUser) {
            // Redirect to login page with redirect back path
            navigate(`/login?redirect=/contest/${contestId}&autojoin=true`);
        } else {
            // Ensure the user is not already a participant
            if (!isParticipant) {
                // Fetch the current user's details from the users collection
                const userRef = doc(db, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error("User does not exist in the database");
                    return;
                }

                const { humanName, squareName } = userSnap.data();

                const contestRef = doc(db, "contests", contestId);
                try {
                    await updateDoc(contestRef, {
                        participants: arrayUnion({
                            id: currentUser.uid,
                            humanName: humanName,
                            squareName: squareName,
                            squares: [],
                            amountPaid: 0
                        })
                    });
                    setIsParticipant(true); // Update the participant state
                    // Optionally, fetch the updated contest data to refresh the local state
                    const updatedContest = (await getDoc(contestRef)).data();
                    setContest(updatedContest);
                } catch (error) {
                    console.error("Error joining contest:", error);
                    // Handle any errors, such as displaying an error message to the user
                }
            }
        }
    };

    const handleSquareClick = async (squareNumber, isSelected) => {
        const contestRef = doc(db, "contests", contestId);
        const docSnap = await getDoc(contestRef);

        if (docSnap.exists()) {
            const contestData = docSnap.data();
            const participants = contestData.participants || [];
            const participantIndex = participants.findIndex(p => p.id === currentUser?.uid);

            // Check if the square is already taken by another participant
            const isSquareTaken = participants.some(p => p.id !== currentUser?.uid && p.squares.includes(squareNumber));

            if (participantIndex !== -1) {
                // Participant found
                let newSquares;
                if (isSelected) {
                    // Deselect the square
                    newSquares = participants[participantIndex].squares.filter(s => s !== squareNumber);
                } else if (!isSquareTaken) {
                    // Select the square only if it's not already taken
                    newSquares = [...participants[participantIndex].squares, squareNumber];
                } else {
                    // Square is taken, so do nothing or show an alert
                    console.log("Square already taken!");
                    return; // Exit the function
                }
                // Update the participant's squares
                const updatedParticipants = [
                    ...participants.slice(0, participantIndex),
                    {
                        ...participants[participantIndex],
                        squares: newSquares
                    },
                    ...participants.slice(participantIndex + 1)
                ];

                await updateDoc(contestRef, { participants: updatedParticipants });
            } else if (!isSelected && !isSquareTaken) {

                const userRef = doc(db, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error("User does not exist in the database");
                    return;
                }

                const { humanName, squareName } = userSnap.data();

                // Participant not found, and selecting a new square that's not taken
                await updateDoc(contestRef, {
                    participants: arrayUnion({
                        id: currentUser?.uid,
                        humanName: humanName,
                        squareName: squareName,
                        squares: [squareNumber]
                    })
                });
            } else {
                // Square is taken or other conditions not met, so do nothing or show an alert
                console.log("Square already taken or other condition not met!");
            }
        } else {
            console.log("No such contest!");
        }
    };


    if (loading) {
        return <CircularProgress />;
    }

    if (!contest) {
        return <Typography variant="h6">Contest not found</Typography>;
    }

    // First, determine if the contest has begun by checking cutoffTime and numbersPopulated
    const contestHasBegun = contest?.cutoffTime?.toDate() <= new Date() && contest?.numbersPopulated;

    // Assuming contest.grids is an array of grid configurations
    return (
        <div style={{}}>
            <Typography className='contest-name' variant="h3" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2.5, color: '#6d4c41' }}>
                {contest.name}
                {/* {currentUser && currentUser.uid === contest.creatorId && !contestHasBegun && (
                    <IconButton aria-label="settings" size="large">
                        <SettingsIcon />
                    </IconButton>
                )} */}
            </Typography>


            {!isParticipant && (
                <Box display="flex" justifyContent="center" marginBottom={'20px'} mt={4}> {/* Use Box for alignment and margin */}
                    <Button variant="contained" onClick={handleJoinContest}>
                        Join This Contest
                    </Button>
                </Box>
            )}

            {isParticipant && contestHasBegun ? (
                <GamesDisplay contest={contest} games={contest.games} />

            ) : (
                <PreStartGrid
                    onSquareClick={(squareNumber, isSelected) => handleSquareClick(squareNumber, isSelected)}
                    gridData={contest} // Make sure `contest` is defined in the parent component's state
                    userSquareNames={userSquareNames} // Pass userSquareNames as prop if it's used
                />
            )}
        </div>
    );
}

export default ContestPage;
