import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Paper } from '@mui/material';

const JoinContest = () => {
    const [contestId, setContestId] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/contest/${contestId}`);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={'100px'}
        // minHeight="100vh"
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '500px', // Set a max-width for larger screens
                    width: '100%', // Ensures it takes up the appropriate width on smaller screens
                    margin: 'auto' // Centers the Paper component horizontally
                }}
            >
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        label="Contest ID"
                        variant="outlined"
                        value={contestId}
                        onChange={(e) => setContestId(e.target.value)}
                        required
                        sx={{ mb: 2 }} // Adds margin below the TextField
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Join Contest
                    </Button>
                </form>
            </Paper>
        </Box>
    );
};

export default JoinContest;
