import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, Button } from '@mui/material';
import LoginForm from '../LoginForm';

const LoginPrompt = ({ open, onClose }) => {
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/login'); // Update with your login route
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="login-prompt-title"
            aria-describedby="login-prompt-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}>
                <Typography id="login-prompt-title" variant="h6" component="h2">
                    Welcome to Football Squares!
                </Typography>
                <Typography id="login-prompt-description" sx={{ mt: 2 }}>
                    Please log in to manage and participate in contests.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={navigateToLogin}
                    sx={{ mt: 2 }}
                >
                    Log In
                </Button>
                <LoginForm />
            </Box>
        </Modal>
    );
};

export default LoginPrompt;
