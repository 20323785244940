
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { auth, signOut } from './firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Divider, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CreateIcon from '@mui/icons-material/Create';
import JoinInnerIcon from '@mui/icons-material/GroupAdd';
import ContestIcon from '@mui/icons-material/EmojiEvents';
import LoginForm from './LoginForm';
import ContestsOverview from './Contests/ContestsOverview';
import CreateContest from './Contests/CreateContest';
import JoinContest from './Contests/JoinContest';
import { AuthProvider } from './auth/AuthContext';
import ContestPage from './Contests/ContestPage'
import ContestManagement from './Contests/ContestManagement';
import PaymentOptions from './Contests/PaymentOptions';
import SendEmail from './SendEmail';

// Import other components as needed

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    signOut(auth);
  };

  const drawerContent = (
    <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List sx={{ width: 250, bgcolor: 'background.paper' }}>
        <ListItem button key="CreateContest" component={Link} to="/create-contest">
          <CreateIcon color="primary" />
          <ListItemText primaryTypographyProps={{ paddingLeft: 1, fontWeight: 'fontWeightMedium' }} primary="Create Contest" />
        </ListItem>
        <ListItem button key="JoinContest" component={Link} to="/join-contest">
          <JoinInnerIcon color="primary" />
          <ListItemText primaryTypographyProps={{ paddingLeft: 1, fontWeight: 'fontWeightMedium' }} primary="Join Contest" />
        </ListItem>
        <ListItem button key="MyContests" component={Link} to="/">
          <ContestIcon color="primary" />
          <ListItemText primaryTypographyProps={{ paddingLeft: 1, fontWeight: 'fontWeightMedium' }} primary="My Contests" />
        </ListItem>
        <Divider />
        {auth.currentUser ?
          <ListItem button key="Logout" onClick={handleLogout}>
            <LogoutIcon color="secondary" />
            <ListItemText primaryTypographyProps={{ paddingLeft: 1, fontWeight: 'fontWeightMedium' }} primary="Logout" />
          </ListItem>
          :
          <ListItem button key="Logout" component={Link} to='/login'>
            <LogoutIcon color="secondary" />
            <ListItemText primaryTypographyProps={{ paddingLeft: 1, fontWeight: 'fontWeightMedium' }} primary='Login' />
          </ListItem>
        }

      </List>
    </div>
  );


  if (loading) {
    return <CircularProgress />;
  }

  // if (!currentUser) {
  //   return <LoginForm />;
  // }

  return (
    <AuthProvider> {/* Wrap Router in AuthProvider */}
      <Router>
        <div style={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerContent}
              </Drawer>
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path="/" element={<ContestsOverview />} />
            <Route path="/create-contest" element={<CreateContest />} />
            <Route path="/join-contest" element={<JoinContest />} />
            <Route path="/contest/:contestId" element={<ContestPage />} />
            <Route path="/contest-management/:contestId" element={<ContestManagement />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/payment-options" element={<PaymentOptions />} />
            <Route path="/send-email" element={<SendEmail />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
