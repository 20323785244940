// PaymentOptions.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const PaymentOptions = () => {
    const location = useLocation();
    const { paymentDetails } = location.state;

    return (
        <Box>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Payment Options
                    </Typography>
                    {Object.entries(paymentDetails).map(([method, link]) => (
                        link &&
                        <Typography variant="body1" key={method}>
                            <Link href={link} target="_blank" rel="noopener">
                                Pay with {method.charAt(0).toUpperCase() + method.slice(1)}
                            </Link>
                        </Typography>
                    ))}
                </CardContent>
            </Card>
        </Box>
    );
};

export default PaymentOptions;
