import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Helps with consistent baseline styling

// Create a theme instance.
const theme = createTheme({
  palette: {
    secondary: {
      main: '#0f80ff',
      light: '#0f80ff',
      dark: '#0f80ff',
      contrastText: '#0f80ff'
    }
  },
  typography: {
    fontFamily: [
      'Roboto', // You can replace this with the font you want to use
      // '"Helvetica Neue"',
      // 'Arial',
      'sans-serif'
    ].join(','),
  },
  // You can also customize the palette, breakpoints, and other theme aspects here
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* CssBaseline helps to maintain consistent baseline styling */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
