import React, { useState } from 'react';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, db } from './firebase-config';
import { updateProfile } from 'firebase/auth';
import { Container, Paper, Typography, TextField, Button, Box, CircularProgress, Link } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import _ from 'underscore'
import { useLocation, useNavigate } from 'react-router-dom';

function LoginForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [squareName, setSquareName] = useState('');
    const [humanName, setHumanName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get('redirect') || '/'; // Fallback to home if no redirect path

    const handlePasswordReset = async () => {
        if (email) {
            try {
                await sendPasswordResetEmail(auth, email);
                setResetEmailSent(true); // Inform the user that the email has been sent
            } catch (error) {
                console.error('Error sending password reset email:', error);
                setError('Failed to send password reset email. Please check your email and try again.');
            }
        } else {
            setError('Please enter your email address.');
        }
    };

    const handleHumanNameChange = (e) => {
        const newHumanName = e.target.value;
        setHumanName(newHumanName);
        // Update squareName only if it hasn't been manually set yet
        if (!squareName || squareName === humanName) {
            setSquareName(newHumanName);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            if (isSignUp) {
                if (squareName.trim()) {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                    await updateProfile(userCredential.user, {
                        displayName: squareName,
                    });

                    // Create a user document in Firestore
                    const userDocRef = doc(db, "users", userCredential.user.uid);
                    await setDoc(userDocRef, {
                        email: email,
                        humanName: humanName,
                        squareName: squareName,
                        // Add other relevant user information here
                    });


                    redirectPath ? navigate(redirectPath) : navigate('/')

                } else {
                    setError('Square Name Empty');
                }

            } else {
                await signInWithEmailAndPassword(auth, email, password);
                navigate(redirectPath);
            }
            setLoading(false);
            // Redirect or update UI
        } catch (error) {
            console.error('Authentication Error:', error);
            setError('Failed to authenticate. Please check your credentials and try again.');
            setLoading(false);
        }
    };

    const toggleAuthMode = () => {
        setIsSignUp(!isSignUp);
        setError(''); // Clear errors when switching modes
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={6} sx={{ marginTop: 8, marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                {/* <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    {isSignUp ? 'Sign Up for Football Squares' : 'Login to Football Squares'}
                </Typography> */}

                <Typography id="login-prompt-title" variant="h6" component="h2">
                    Welcome to Football Squares!
                </Typography>
                <Typography id="login-prompt-description" sx={{ mt: 2, marginX: '20px', textAlign: 'center' }}>
                    Please {isSignUp ? 'sign up' : 'log in'} to manage and participate in contests.
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {isSignUp && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="humanName"
                            label="Human Name"
                            name="humanName"
                            autoComplete="name"
                            value={humanName}
                            onChange={handleHumanNameChange}
                            sx={{ mb: 2 }}
                        />
                    )}
                    {isSignUp && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="squareName"
                            label="Square Name"
                            name="squareName"
                            autoComplete="off"
                            value={squareName}
                            onChange={(e) => setSquareName(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : isSignUp ? 'Sign Up' : 'Log In'}
                    </Button>
                    {isSignUp ? (
                        <Button
                            type="button"
                            fullWidth
                            variant="text"
                            onClick={toggleAuthMode}
                        >
                            Already have an account? Log In
                        </Button>
                    ) : (
                        <>
                            <Button
                                type="button"
                                fullWidth
                                variant="text"
                                onClick={toggleAuthMode}
                            >
                                Don't have an account? Sign Up
                            </Button>
                            <Button
                                type="button"
                                fullWidth
                                variant="text"
                                onClick={handlePasswordReset}
                                sx={{ mt: 2 }}
                            >
                                Forgot Password?
                            </Button>
                        </>
                    )}
                </Box>
                {resetEmailSent && <Typography sx={{ mt: 2 }}>Check your email for a link to reset your password.</Typography>}
                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
            </Paper>
        </Container>
    );
}

export default LoginForm;
