

import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { useAuth } from '../auth/AuthContext';
import { Button, Box, Typography, Divider, Container, Card, Grid, CardContent, Stack, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import LoginPrompt from '../auth/LoginPrompt';

const ContestsOverview = () => {
    const [createdContests, setCreatedContests] = useState([]);
    const [joinedContests, setJoinedContests] = useState([]);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check if the auth state has been initialized
        if (currentUser !== undefined) { // or null, depending on your auth context's initial value
            setLoading(false); // Auth state is confirmed, so we can turn off the loader

            if (!currentUser) {
                // User is not logged in
                navigate('/login');
            } else {
                // User is logged in
                fetchCreatedContests();
                fetchJoinedContests();
            }
        }
    }, [currentUser, navigate]);


    const handleContestClick = (contestId) => {
        navigate(`/contest/${contestId}`); // Assuming the route is structured like this
    };

    const handleManageClick = (contestId) => {
        navigate(`/contest-management/${contestId}`)
    }

    const fetchCreatedContests = async () => {
        const createdQuery = query(collection(db, "contests"), where("creatorId", "==", currentUser.uid));
        const createdSnapshot = await getDocs(createdQuery);
        setCreatedContests(createdSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    const fetchJoinedContests = async () => {
        const contestsSnapshot = await getDocs(collection(db, "contests"));
        const contests = contestsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        // const joined = contests.filter(contest => contest.participants?.some(p => p.id === currentUser.uid))
        const joined = contests.filter(contest => {
            // Debug: Log the current contest
            console.log('Current contest:', contest);

            // Check if the 'participants' property exists and is an array
            if (!Array.isArray(contest.participants)) {
                console.log('No participants array in contest:', contest);
                return false;
            }

            // Check if any participant matches the current user's ID
            const hasCurrentUser = contest.participants.some(p => {
                const isCurrentUser = p.id === currentUser.uid;
                // Debug: Log each participant check
                console.log(`Checking participant: ${p.id}, isCurrentUser: ${isCurrentUser}`);
                return isCurrentUser;
            });

            return hasCurrentUser;
        });

        setJoinedContests(joined);
    };

    const deleteContest = async (id) => {
        await deleteDoc(doc(db, "contests", id));
        fetchCreatedContests(); // Refresh the list after deletion
    };

    if (loading) {
        return <CircularProgress />; // Show a loading indicator while checking the auth state
    }
    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h3" gutterBottom component="div" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#123456' }}>
                    My Contests
                </Typography>

                <Box sx={{ my: 4 }}>
                    <Typography variant="h5" component="div" sx={{ mb: 2, fontWeight: 'medium', color: '#345678' }}>
                        Joined Contests
                    </Typography>
                    <Grid container spacing={2}>
                        {joinedContests.map(contest => (
                            <Grid item xs={12} md={6} lg={4} key={contest.id}>
                                <Card variant="outlined" sx={{ p: 2, ':hover': { boxShadow: 6 } }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" sx={{ mb: 2, fontWeight: 'regular', color: '#456789' }}>
                                            {contest.name}
                                        </Typography>
                                        {/* Additional contest details */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleContestClick(contest.id)}
                                        >
                                            View Contest
                                        </Button>

                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Box sx={{ my: 4 }}>
                    <Typography variant="h5" component="div" sx={{ mb: 2, fontWeight: 'medium', color: '#345678' }}>
                        Created by Me
                    </Typography>
                    {/* Use a Grid container to evenly space out cards */}
                    <Grid container spacing={2}>
                        {createdContests.map(contest => (
                            <Grid item xs={12} md={6} lg={4} key={contest.id}>
                                <Card variant="outlined" sx={{ p: 2, ':hover': { boxShadow: 6 } }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" sx={{ mb: 2, fontWeight: 'regular', color: '#456789' }}>
                                            {contest.name}
                                        </Typography>
                                        {/* Additional contest details */}
                                        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleContestClick(contest.id)}
                                            >
                                                View Contest
                                            </Button>
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                onClick={() => handleManageClick(contest.id)}
                                            >
                                                Manage Contest
                                            </Button>
                                        </Stack>
                                        {/* <Stack direction="row" justifyContent="center"> */}
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this contest?')) {
                                                    deleteContest(contest.id);
                                                }
                                            }}
                                        >
                                            Delete Contest
                                        </Button>
                                        {/* </Stack> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                        }


                    </Grid>
                </Box>

                <Divider sx={{ my: 4 }} />


            </Box>
        </Container>

    );
};

export default ContestsOverview;
